import "./bootstrap";
import $ from "jquery";
import Alpine from "alpinejs";
import "slick-carousel";
import "lazysizes";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import Swal from "sweetalert2";
import AOS from "aos";
import "flowbite";
import "./fullpage";
import "./fullpage.extensions";
import Swiper from "swiper/bundle";
import "select2";
import { CountUp } from "countup.js";
window.$ = window.jQuery = $;
window.AOS = AOS;
window.Fancybox = Fancybox;
window.Swal = Swal;
window.Alpine = Alpine;
window.Swiper = Swiper;
window.CountUp = CountUp;
// Initialise jQuery
$(function () {
    // Any jQuery scripts...
    AOS.init({
        // once: true,
    });
    Fancybox.bind("[data-fancybox]", {});
    Alpine.start();

    // Find all Statistics on page, put them inside a variable
    var counter = $(".counterUp");

    // For each Statistic we find, animate it
    counter.each(function (index) {
        // Find the value we want to animate (what lives inside the p tags)
        var value = $(counter[index]).html();
        // Start animating
        var statisticAnimation = new CountUp(counter[index], value, {
            enableScrollSpy: true,
        });
        statisticAnimation.start();
    });
});
const loading = {
    start: function () {
        $("#loading2").css("display", "flex");
    },
    stop: function () {
        $("#loading2").css("display", "none");
    },
};
$.loading = loading;
/* Hiệu ứng chạy chữ tìm kiếm */
$(document).ready(function () {
    const message = "Nhập tên sản phẩm...";
    let index = 0;

    function type() {
        if (index < message.length) {
            $(".animation__search").attr(
                "placeholder",
                message.substring(0, index + 1)
            );
            index++;
            setTimeout(type, 100);
        } else {
            setTimeout(resetType, 1000);
        }
    }

    function resetType() {
        index = 0;
        type();
    }
    type();
});
